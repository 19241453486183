import React from 'react';
import { graphql } from 'gatsby';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { MenuItem } from '@material-ui/core';
import { Form } from 'react-final-form';
import { makeRequired, makeValidate, TextField, Select } from 'mui-rff';
import * as Yup from 'yup';
import styled from 'styled-components';

import { NAUTA_PATTERN } from '../constants/Regexp';

import Layout from '../components/layouts/layout';
import SEO from '../components/seo';
import ServiceHeading from '../components/layouts/serviceHeading';
import CtaSection from '../components/ctaSection';
import HelpCenterLayout from '../components/layouts/helpCenterLayout';
import FaqAccordion from '../components/faqAccordion';
import ContactSection from '../components/contactSection';
import RechargeNavLinks from '../components/rechargeNavLinks';

import RechargeImage from '../images/bg-recarga-nauta.png';
import MobileImageSrc from '../images/mobile-app-2.png';
import EmoteImage from '../images/emotes-nauta.png';

const ContainerWrapper = styled.div`
  @media (max-width: 767px) {
    // margin-top: 9rem;
  }
`;

const AdjustableCol = styled(Col)`
  @media (max-width: 767px) {
    // height: 14rem;
  }
`;

const MobileImage = styled.img`
  width: 80%;

  @media (max-width: 767px) {
    position: relative;
    top: -10rem;
    width: 100%;
  }
`;

const fakeAmounts = [
  {
    id: 1,
    title: '250 CUP Nauta',
    description: 'Tu contacto recibe 250 CUP',
    value: '19.19',
    previousValue: '22.89',
  },
  {
    id: 2,
    title: '250 CUP Nauta',
    description: 'Tu contacto recibe 250 CUP',
    value: '19.19',
    previousValue: '22.89',
  },
  {
    id: 3,
    title: '250 CUP Nauta',
    description: 'Tu contacto recibe 250 CUP',
    value: '19.19',
    previousValue: '22.89',
  },
  {
    id: 4,
    title: '250 CUP Nauta',
    description: 'Tu contacto recibe 250 CUP',
    value: '19.19',
    previousValue: '22.89',
  },
];

const faqLeft = [
  {
    title: '¿En qué tiendas puedo recargar personalmente?',
    description: 'Hello World',
  },
  {
    title: '¿Puedo recargar desde cualquier parte del mundo?',
    description: 'Hello World',
  },
  {
    title: '¿Cómo sé que le llegó la recarga a mi familiar o amigo?',
    description: 'Hello World',
  },
  {
    title: '¿Cómo mi familiar o amigo se entera que recibió el saldo?',
    description: 'Hello World',
  },
];

const faqRight = [
  {
    title: '¿Recargué un número equivocado, cómo puedo recuperar mi dinero?',
    description: 'Hello World',
  },
  {
    title: '¿Cómo puedo saber cuándo hay promoción de recargas?',
    description: 'Hello World',
  },
  {
    title: 'Mi familiar no recibió la recarga completa, solo el saldo.',
    description: 'Hello World',
  },
  {
    title: '¿Solo puedo recargar a números en Cuba?',
    description: 'Hello World',
  },
  {
    title: '¿Puedo pagar el plan prepago de mi celular en USA?',
    description: 'Hello World',
  },
];

const NautaRechargePage = () => {
  const schema = Yup.object().shape({
    // Email validations
    email: Yup.string()
      .required('La cuenta Nauta es requerida. ')
      .matches(NAUTA_PATTERN, {
        message: 'La cuenta debe terminar en @nauta.com.cu ó @nauta.co.cu. ',
        excludeEmptyString: true,
      }),

    // Offer validation
    offer: Yup.number().required().min(1, 'Requerido. '),
  });

  const initialValues = {
    email: '',
    offer: 1,
    promoCode: '',
  };

  const validate = makeValidate(schema);
  const required = makeRequired(schema);

  const onSubmit = (values) => {
    // TODO implementar esto
    console.log(values);
  };

  return (
    <Layout
      headerProps={{ 
        fixedHeaderBg: true,
        disableLogin: true,
        landingOnly: true,
        NavigationOptions: <RechargeNavLinks/>,
      }}
    >
      <SEO title="Recargar Nauta" />

      <ServiceHeading
        image={RechargeImage}
        title="Recarga Nauta"
        subtitle="Wifi y Nauta Hogar"
        actionLabel="Recargar ahora"
      >
        <p>Recarga Nauta para que tus familiares puedan navegar en Internet.</p>

        <Form
          initialValues={initialValues}
          validate={validate}
          onSubmit={onSubmit}
          render={({ handleSubmit }) => (
            <form
              id="rechargeForm"
              noValidate
              onSubmit={handleSubmit}
            >
              <TextField
                id="email"
                key="email"
                name="email"
                label="Cuenta Nauta"
                fullWidth
                required={required.email}
              />

              <Select
                id="offer"
                key="offer"
                name="offer"
                label="Ofertas"
                fullWidth
                required={required.offer}
                displayEmpty
              >
                {fakeAmounts.map((item, index) => (
                  <MenuItem key={`amount-${index}`} value={item.id}>
                    <div className="d-flex flex-column w-100">
                      <div className="d-flex flex-row align-items-center justify-content-between">
                        <div className="fw-bold">{item.title}</div>
                        <div className="fw-bold text-decoration-line-through text-muted text-end">
                          ${item.previousValue}
                        </div>
                      </div>
                      <div className="d-flex flex-row align-items-center justify-content-between">
                        <div className="fw-normal small">{item.description}</div>
                        <div className="fw-bold text-end">${item.value}</div>
                      </div>
                    </div>
                  </MenuItem>
                ))}
              </Select>

              <TextField
                id="promoCode"
                key="promoCode"
                name="promoCode"
                label="¿Tienes un código promocional?"
                fullWidth
              />
            </form>
          )}
        />
      </ServiceHeading>

      <Container className="fs-5 mt-4 mt-lg-8 px-md-7">
        <h1 className="text-success mb-4">Tarifas de conexión Nauta</h1>
        <p>
          Las tarifas de acceso a internet en Cuba a través de cuenta de acceso
          permanente Nauta son establecidas por Etecsa y no están relacionadas
          con Islacel. Actualmente, para navegación internacional es de 17.50
          CUP por hora. Para navegación nacional es de 2.50 CUP por hora. El
          ciclo de vida de una cuenta de acceso permanente Nauta es de 330 días.
          Este se renueva cada vez que pones una recarga Nauta.
        </p>
      </Container>

      <Container className="mt-4 mt-md-0">
        <hr className="d-none d-md-block my-4"/>
      </Container>

      <CtaSection />

      <ContainerWrapper className="bg-white py-4 py-md-5 mb-5">
        <Container>
          <Row className="align-items-start">
            <Col xs={{ order: 'last' }} md={{ order: 'first' }}>
              <h2 className="text-success">
                ¿Qué son las recargas Nauta a Cuba?
              </h2>
              <p>
                Son las recargas que envías para que tus familiares se conecten
                a internet en Cuba usando el servicio Nauta en hoteles,
                aeropuertos o áreas WiFi. Para recargar Nauta solo necesitas la
                dirección de correo de la Cuenta de Acceso Permanente Nauta de
                tu familiar, que luce así nombre@nauta.com.cu y se obtiene en
                cualquier oficina de Etecsa.
              </p>
              <p>
                La diferencia con Internet en el celular, es que la conexión
                Nauta tiene mayor velocidad y soporta mejor el envío de fotos,
                archivos y videos o las videollamadas.
              </p>

              <h2 className="text-success">¿Sirve para Nauta Hogar?</h2>
              <p>
                La recarga Nauta sirve también para recargar Nauta Hogar:
                navegación por Internet con tecnología ADSL sin salir de casa.
              </p>

              <p>
                <Button className="shadow-none">Recargar ahora</Button>
              </p>
            </Col>
            <AdjustableCol md className="d-none d-md-block text-center text-md-end">
              <MobileImage src={MobileImageSrc} />
            </AdjustableCol>
          </Row>
        </Container>
      </ContainerWrapper>

      <HelpCenterLayout
        title="¿Cómo recargar Nauta a Cuba desde la app?"
        image={EmoteImage}
      >
        <ol className="ol-color">
          <li>Descarga el app de Islacel para iPhone o Android.</li>
          <li>Si ya tienes una cuenta Islacel, entra al app con tus credenciales. Si no, crea una nueva.</li>
          <li>Pincha en el ícono RECARGAS que encontrarás en el menú inferior de la app. Luego pincha en RECARGA NAUTA.</li>
          <li>Completa los datos de tu contacto (necesitarás saber su correo Nauta que luce así: nombre@nauta.com.cu), selecciona la cantidad a recargar y pincha en el botón AÑADIR RECARGA AL CARRITO.</li>
          <li>Sigue las instrucciones de la app para proceder al pago.</li>
        </ol>
      </HelpCenterLayout>

      <FaqAccordion
        leftCol={faqLeft}
        rightCol={faqRight}
      />

      <ContactSection/>
    </Layout>
  );
};

export default NautaRechargePage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
